import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { imageUrl, url } from "../../baseUrl";
import DatatablePage from "../../Components/DataTable/DataTable";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CreateIcon from "@mui/icons-material/Create";
import {
  darkButton,
  darkText,
  lightBackground,
  // lightText,
  white,
} from "../../Assets/Theme/ThemeColors";
// import { useSelector } from "react-redux";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Navbar from "../../Components/Navbar/Navbar";
import BasicSelect from "../../Components/CustomSelect/Select";
// import { Category } from "@mui/icons-material";
import FormDialog from "../../Components/Popup/Popup";
import CustomImage from "../../Components/Image/CustomImage";

const TransactionList = () => {
  const [data, setData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [category, setCategory] = useState("illustrations");
  const [subCategory, setSubCategory] = useState("illustration");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedContent, setSelectedContent] = useState();

  const [categoryOpt, setCategoryOpt] = useState([
    {
      key: "animation",
      value: "Animation",
    },
    {
      key: "illustrations",
      value: "Illustrations",
    },
    {
      key: "graphicdesigning",
      value: "Graphic Designing",
    },
    {
      key: "bookillustration",
      value: "Book Illustration",
    },
  ]);
  const [subCategoryOpt, setSubCategoryOpt] = useState([
    {
      key: "2danimation",
      value: "2D Animation",
    },
    {
      key: "3danimation",
      value: "3D Animation",
    },
    {
      key: "whiteboard",
      value: "Whiteboard Animation",
    },
    {
      key: "demovideos",
      value: "Demo Videos",
    },
    {
      key: "illustration",
      value: "Illustration",
    },
    {
      key: "graphic design",
      value: "Graphic Design",
    },
    {
      key: "book illustration",
      value: "Book Illustration",
    },
    {
      key: "Digital Illustrations",
      value: "Digital Illustrations",
    },
    {
      key: "Book Covers",
      value: "Book Covers",
    },
    {
      key: "Animal Illustrations",
      value: "Animal Illustrations",
    },
    {
      key: "logo",
      value: "Logo",
    },
    {
      key: "Gifs",
      value: "GIFs",
    },
  ]);
  let navigate = useNavigate();
  // const navigate = useNavigate();
  const handleClickOpen = (item) => {
    setSelectedContent({
      ...item,
    });
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
    getAllContent();
  };
  const columns = [
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 100,
    },
    {
      label: "Category",
      field: "categoryType",
      sort: "asc",
      width: 100,
    },
    {
      label: "Sub Category",
      field: "subCategory",
      sort: "asc",
      width: 100,
    },
    {
      label: "Sub Category",
      field: "subCategory",
      sort: "asc",
      width: 100,
    },

    {
      label: "Date",
      field: "updatedAt",
      sort: "asc",
      width: 200,
    },

    {
      label: "Content",
      field: "content",
      sort: "asc",
      width: 250,
    },
  ];
  useEffect(() => {
    getAllContent();
  }, [subCategory]);

  const getAllContent = async () => {
    var isCat = false;
    console.log("sub cat", subCategory, category);
    if (
      subCategory === "2danimation" ||
      subCategory === "3danimation" ||
      subCategory === "whiteboard" ||
      subCategory === "demovideos"
    ) {
      setCategory("animation");
      isCat = true;
    } else {
      setCategory("illustrations");
      isCat = true;
    }
    if (isCat) {
      try {
        setIsLoaded(true);

        const response = await axios
          .get(
            `${url}/project/filterProjects?category=${category}&subCategory=${subCategory}`
          )
          .then((data) => {
            console.log("data images", data.data.data);

            setData({
              columns,
              rows: data.data.data.map((item) => ({
                ...item,
                updatedAt: item.updatedAt.split("T")[0],
                content:
                  item.categoryType === "animation" ? (
                    <video width={60}>
                      <source src={imageUrl + item.fileData[0]} />
                    </video>
                  ) : (
                    <CustomImage url={imageUrl + item.fileData[0]} />
                  ),
                action: (
                  <>
                    <span
                      onClick={(e) => {
                        handleClickOpen(item);
                      }}
                      style={{ color: `${lightBackground}`, cursor: "pointer" }}
                      title="Upload New File"
                    >
                      <CloudUploadIcon />
                    </span>
                  </>
                ),
              })),
            });
            setIsOpen(true);
            setMessage("Data Loaded!");
            setSeverity("success");
            setIsLoaded(false);
          });
      } catch (error) {
        console.log("error", error.message);
        setIsLoaded(false);
        setIsOpen(true);
        setMessage(error.message);
        setSeverity("error");
      }
      setTimeout(() => {
        setIsOpen(false);
      }, 2000);
    }
  };

  return (
    <>
      <Navbar />

      <Container
        style={{
          maxWidth: "100%",
          padding: "0px",
          overflowY: "scroll",
          height: "94vh",
        }}
      >
        <Paper fullWidth sx={{ m: 1, p: 2, maxWidth: "100%" }}>
          <Grid
            container
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography variant="h5" color={`${darkText} `}>
              All Content
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            {/* <Grid sx={{ p: 1 }}>
              <BasicSelect
                label={"Category"}
                options={categoryOpt}
                setValue={setCategory}
                value={category}
              />
            </Grid> */}
            <Grid sx={{ p: 1 }}>
              <BasicSelect
                label={"Select Content Type"}
                options={subCategoryOpt}
                setValue={setSubCategory}
                value={subCategory}
              />
            </Grid>
            <Grid sx={{ p: 1 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{
                  height: "55px",
                  width: "150px",
                  background: `${lightBackground}`,
                  color: `${white}`,
                }}
                onClick={getAllContent}
                // sx={{ mt: 3, mb: 2 }}
              >
                {isLoaded ? (
                  <span>
                    {" "}
                    <CircularProgress
                      style={{
                        color: `${white}`,
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  </span>
                ) : (
                  " Get Data"
                )}
              </Button>
            </Grid>
          </Grid>
          <DatatablePage data={data} isLoaded={isLoaded} />
        </Paper>
      </Container>
      <FormDialog
        open={openDialog}
        handleClose={handleClose}
        selectedContent={selectedContent}
      />
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
    </>
  );
};

export default TransactionList;
