export const header = "#000066";
// export const navbar = "#000000";
// export const lightText = "#4f4c4c";
export const darkText = "#000000";
export const lightButton = "#eeecff";
export const darkButton = "#ffcd24 ";
export const dimBackground = "#e6e3e3";
export const lightBackground = "#00004d";
export const darkBackground = "#303030";
export const white = "#ffffff";

//theme color for Brand Invoice 

export const navbar = "#000066";
export const navbarText = "#000000";
export const lightText = "#ffffff";
export const mainBackgound = "#131e3c";
export const invoiceCard = "#cecaca";
export const button = "#ff4f01";
export const cardBackground = "#cecaca";

