import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { lightBackground, white } from "../../Assets/Theme/ThemeColors";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector } from "react-redux";
import axios from "axios";
import { url } from "../../baseUrl";
import { CircularProgress } from "@mui/material";

export default function FormDialog({ open, handleClose, selectedContent }) {
  const [isUpdated, setIsUpdated] = React.useState(false);
  console.log("selectedContent", selectedContent);
  const [attachment, setAttachment] = React.useState();
  const state = useSelector((state) => state.user.data);

  const updateImage = async () => {
    setIsUpdated(true);
    console.log("update Image");
    const formData = new FormData();
    formData.append("attachment", attachment);
    formData.append("_id", selectedContent._id);
    try {
      const response = await axios.post(
        `${url}/project/updateProject`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      console.log(response);
      if (response.data.success) {
        setIsUpdated(false);
        handleClose();
      }
    } catch (error) {
      console.log(error.message);
      handleClose();
      setIsUpdated(false);
    }
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>
          <CloudUploadIcon /> Upload New File
        </DialogTitle>
        <DialogContent
          className="flex"
          sx={{
            height: "10vh",
            // width: "20vw",
          }}
        >
          <input
            type="file"
            id="myFile"
            onChange={(e) => setAttachment(e.target.files[0])}
            name="filename"
          ></input>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              background: `${white}`,
              color: `${lightBackground}`,
              border: `1px solid ${lightBackground}`,
            }}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            sx={{ background: `${lightBackground}`, color: `${white}` }}
            onClick={updateImage}
          >
            {isUpdated ? (
              <span>
                {" "}
                <CircularProgress
                  style={{
                    color: `${white}`,
                    height: "20px",
                    width: "20px",
                  }}
                />
              </span>
            ) : (
              "Update"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
