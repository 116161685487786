import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import SignIn from "./SignIn/SignIn";
import TransactionList from "./Transactions/TransactionList";

const Routers = () => {
  const state = useSelector((state) => state.user.data.token);
  console.log(state);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            state ? <Navigate to="/transaction/list" replace /> : <SignIn />
          }
        />
      
        <Route
          path="/transaction/list"
          element={!state ? <Navigate to="/" replace /> : <TransactionList />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
